/*
  Settings component Theme thumbnails
*/

.theme-1-thumb{
  background-color: #39e09b;
  flex-grow: 1;
  border-radius: 10px;
}

.theme-1-thumb .dot {
  background-color: #1f1c1c;
}

.theme-2-thumb{
  background-color: #1f1c1c;
  flex-grow: 1;
  border-radius: 10px;
}

.theme-2-thumb .dot {
  background-color: #39e09b;
}

.theme-3-thumb{
  background-color: #39e09b;
  flex-grow: 1;
  border-radius: 10px;
}

.theme-3-thumb .dot {
  background-color: white;
}

.theme-4-thumb{
  background-color: #1f1c1c;
  flex-grow: 1;
  border-radius: 10px;
}

.theme-4-thumb .dot {
  background-color: white;
}

.theme-5-thumb{
  background: #654ea3;  /* fallback for old browsers */
  background: -webkit-linear-gradient(to bottom, #eaafc8, #654ea3);  /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to bottom, #eaafc8, #654ea3); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */  
  flex-grow: 1;
  border-radius: 10px;
}

.theme-5-thumb .dot {
  border: 2px solid white;
  margin-top: 18.25px;
}

.theme-6-thumb{
  background: #6441A5;  /* fallback for old browsers */
  background: -webkit-linear-gradient(to top, #2a0845, #bf94ff);  /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to top, #2a0845, #bf94ff); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */  
  flex-grow: 1;
  border-radius: 10px;
}

.theme-6-thumb .dot {
  border: 2px solid white;
  margin-top: 18.25px;
}

.theme-7-thumb{
  background: #283048;  /* fallback for old browsers */
  background: -webkit-linear-gradient(to top, #859398, #283048);  /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to top, #859398, #283048); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */  
  flex-grow: 1;
  border-radius: 10px;
}

.theme-7-thumb .dot {
  border: 2px solid white;
  margin-top: 18.25px;
}

.theme-8-thumb{
  background: #2BC0E4;  /* fallback for old browsers */
  background: -webkit-linear-gradient(to bottom, #EAECC6, #2BC0E4);  /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to bottom, #EAECC6, #2BC0E4); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */  
  flex-grow: 1;
  border-radius: 10px;
}

.theme-8-thumb .dot {
  border: 2px solid white;
  margin-top: 18.25px;
}

.dot {
  height: 50%;
  width: 50%;
  border-radius: 50%;
  display: inline-block;
  margin-top: 20.25px;
}

/*
  Theme 1 is applied by default
*/

/*
  Theme 2
*/
.theme-2 {
  position: absolute;
  top: 0;
  bottom: 0;
  height: 100%;
  width: 100%;
  background-color: #1f1c1c;
  overflow: auto;
}

.theme-2 .link {
  background-color: #39e09b;
}

.theme-2 .link:hover {
  background-color: #1f1c1c;
}

.theme-2 .linkContainer {
  border-color: #39e09b;
  color: #1f1c1c;
}

.theme-2 .linkContainer:hover {
  color: #39e09b;
}

.theme-2 h2  {
  color: #39e09b;
}

/*
  Theme 3
*/
.theme-3 {
  position: absolute;
  top: 0;
  bottom: 0;
  height: 100%;
  width: 100%;
  background-color: #39e09b;
  overflow: auto;
}

.theme-3 .link {
  background-color: white;
}

.theme-3 .link:hover {
  background-color: #39e09b;
}

.theme-3 .linkContainer {
  border-color: white;
  color: #39e09b; 
}

.theme-3 .linkContainer:hover {
  color: white;
}

.theme-3 h2  {
  color: white;
}

/*
  Theme 4
*/
.theme-4 {
  position: absolute;
  top: 0;
  bottom: 0;
  height: 100%;
  width: 100%;
  background-color: #1f1c1c;
  overflow: auto;
}

.theme-4 .link {
  background-color: white;
}

.theme-4 .link:hover {
  background-color: #1f1c1c;
}

.theme-4 .linkContainer {
  border-color: white;
  color: #1f1c1c;
}

.theme-4 .linkContainer:hover {
  color: white;
}

.theme-4 h2  {
  color: white;
}

/*
  Theme 5
*/
.theme-5 {
  position: absolute;
  top: 0;
  bottom: 0;
  height: 100%;
  width: 100%;
  background: #654ea3;  /* fallback for old browsers */
  background: -webkit-linear-gradient(to bottom, #eaafc8, #654ea3);  /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to bottom, #eaafc8, #654ea3); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */   
  overflow: auto;
}

.theme-5 .link {
  background-color: transparent;
}

.theme-5 .link:hover {
  background-color: white;
}

.theme-5 .linkContainer {
  border-color: white;
  color: white;
}

.theme-5 .linkContainer:hover {
  color: #eaafc8;
}

.theme-5 h2  {
  color: white;
}

/*
  Theme 6
*/
.theme-6 {
  position: absolute;
  top: 0;
  bottom: 0;
  height: 100%;
  width: 100%;
  background: #6441A5;  /* fallback for old browsers */
  background: -webkit-linear-gradient(to top, #2a0845, #bf94ff);  /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to top, #2a0845, #bf94ff); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */  
  overflow: auto;
}

.theme-6 .link {
  background-color: transparent;
}

.theme-6 .link:hover {
  background-color: white;
}

.theme-6 .linkContainer {
  border-color: white;
  color: white;
}

.theme-6 .linkContainer:hover {
  color: #bf94ff;
}

.theme-6 h2  {
  color: white;
}

/*
  Theme 7
*/
.theme-7 {
  position: absolute;
  top: 0;
  bottom: 0;
  height: 100%;
  width: 100%;
  background: #283048;  /* fallback for old browsers */
  background: -webkit-linear-gradient(to top, #859398, #283048);  /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to top, #859398, #283048); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */ 
  overflow: auto; 
}

.theme-7 .link {
  background-color: transparent;
}

.theme-7 .link:hover {
  background-color: white;
}

.theme-7 .linkContainer {
  border-color: white;
  color: white;
}

.theme-7 .linkContainer:hover {
  color: #283048;
}

.theme-7 h2  {
  color: white;
}

/*
  Theme 8
*/
.theme-8 {
  position: absolute;
  top: 0;
  bottom: 0;
  height: 100%;
  width: 100%;
  background: #2BC0E4;  /* fallback for old browsers */
  background: -webkit-linear-gradient(to bottom, #EAECC6, #2BC0E4);  /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to bottom, #EAECC6, #2BC0E4); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */  
  overflow: auto;
}

.theme-8 .link {
  background-color: transparent;
}

.theme-8 .link:hover {
  background-color: white;
}

.theme-8 .linkContainer {
  border-color: white;
  color: white;
}

.theme-8 .linkContainer:hover {
  color: #2BC0E4;
}

.theme-8 h2  {
  color: white;
}