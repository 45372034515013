* {
  font-size: 1rem;
  font-family: Karla,Helvetica,sans-serif;
}

html {
  height: 100%;
}

body {
  margin: 0;
  height: 100%;
}

h2 {
  color: #1f1c1c
}

.green-container {
  position: absolute;
  top: 0;
  bottom: 0;
  height: 100%;
  width: 100%;
  background-color: #39e09b;
  overflow: auto;
}

.linksList {
  display: block;
  color: #1f1c1c;
  text-align: center;
  max-width: 680px;
  position: center;
  margin: auto;
}

.linkContainer {
  display: block;
  border-style: solid;
  border-color: #1f1c1c;
  margin-bottom: 12px;
  text-align: center;
  padding: 17px;
  text-decoration: none;
  color: #39e09b;
  overflow: hidden;
}

.linkContainer:hover {
  color: #1f1c1c;
}

.avatarStyle {
  border-radius: 50%;
  width: 96px;
  height: 96px;
}

.userContainer {
  text-align: center;
  font-size: 12px;
  margin: 30px;
}

.link {
  font-weight: 700;
  background-color: #1f1c1c;
}

.link:hover {
  background-color: #39e09b;
}

.loader {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 60vh;
  overflow: hidden;
}

.avatar-loader {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 20vh;
  overflow: hidden;
}

.buttons {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 80vh;
  overflow: hidden;
}

.error-msg {
  color: rgb(204, 0, 0);
  position: absolute;
  top: 30vh;
  width: 100%;
  text-align: center;
  font-weight: 700;
}

.error-msg p {
  display: inline;
  margin: 5px;
}

.link-error-msg {
  color: rgb(204, 0, 0);
  position: absolute;
  top: 20vh;
  width: 100%;
  text-align: center;
  font-weight: 700;
}

.link-error-msg p {
  display: inline;
  margin: 5px;
}



.login-buttons {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 80vh;
  overflow: hidden;
}

.login-buttons input {
  margin: 10px;
}

.login-buttons button {
  height: 82px;
}

.newlink-buttons {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 35vh;
  overflow: hidden;
}

.newlink-buttons input {
  margin: 10px;
}

.newlink-buttons button {
  height: 82px;
}

.signup-buttons {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 80vh;
  overflow: hidden;
}

.signup-buttons input {
  margin: 10px;
}

.signup-buttons button {
  height: 82px;
}

.sign-in-button:hover {
  color: rgb(31, 28, 28);
  background-color: white
}

.sign-in-button {
  background-color: rgb(31, 28, 28);
  text-decoration: none;
  padding: 30px 50px 30px 50px;
  font-weight: 700;
  letter-spacing: 1.5px;
  color: white;
  cursor: pointer;
  text-transform: uppercase;
  margin: 50px;
  min-width: 83.13px;
  text-align: center;
}

.sign-in-button p {
  margin: 0px;
}

#or {
  color: white;
  font-weight: 700;
  letter-spacing: 1.5px;
}

.user-input {
  background-color: white;
  cursor: auto;
  border-color: rgb(31, 28, 28);
  border-width: 2px;
  padding: 30px 50px 30px 50px;
  font-weight: 700;
  letter-spacing: 1.5px;
}

.user-submit {
  background-color: rgb(31, 28, 28);
  cursor: pointer;
  border: none;
  padding: 30px 50px 30px 50px;
  font-weight: 700;
  letter-spacing: 1.5px;
  color: white
}

.user-submit:hover {
  color: rgb(31, 28, 28);
  background-color: white;
}

input::placeholder {
  color: rgb(31, 28, 28);
}

input {
  color: rgb(31, 28, 28);
}

.footer {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  text-align: center;
}

.footer a {
  color: white;
  text-decoration: none;
  font-size: 20px;;
}

.flex-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 700px;
}

.new-link {
  background-color: white;
  padding: 50px;
  border-radius: 3px;
}

.admin-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background-color: rgb(31, 28, 28);
  color: white;
  padding: 15px;
  height: 30px;
}

.header-links {
  text-decoration: none;
  font-weight: 700;
  letter-spacing: 1.5px;
  color: white;
  cursor: pointer;
  text-transform: uppercase;
  margin: 50px;
  text-align: center;
}

.header-links:hover {
  color: rgb(31, 28, 28);
  background-color: white;
}

.active {
  border-bottom: 3px solid #39e09b;
}

.remove-button {
  position: relative;
  border: 2px solid rgb(31, 28, 28);
  padding: 5px;
  text-align: center;
  top: -35px;
  cursor: pointer;
}

.remove-button:hover {
  background-color: rgb(31, 28, 28);
  color: white;
}

.error-text::placeholder {
  color: rgb(204, 0, 0);
}

.error-text {
  border-color: red;
}

#added {
  color: white;
  font-size: 30px;
}

.edit-link-container {
  position: relative;
  background-color: white;
  font-weight: 700;
  margin-top: 10px;
  cursor: default;
  display: block;
  border-style: solid;
  border-color: #1f1c1c;
  margin-bottom: 12px;
  text-align: center;
  padding: 20px;
  text-decoration: none;
  color: #1f1c1c;

}

.edit-linkTitle {
  margin-bottom: 20px;
  position: relative;
}

.edit-linkTitle-wrap {
  display: inline-block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 550px;
}

.edit-url {
  position: relative;
}

.edit-url-wrap {
  display: inline-block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 530px;
}

.error-url-text::placeholder {
  color: rgb(204, 0, 0);
}

.error-url-text {
  border-top-style: hidden;
  border-right-style: hidden;
  border-left-style: hidden;
  border-bottom-style: groove;
  border-bottom-color: red;
}

.edit-link-input {
  border-top-style: hidden;
  border-right-style: hidden;
  border-left-style: hidden;
  border-bottom-style: groove;
  border-bottom-color: #39e09b;
}

.edit-link-input:focus {
  outline: none;
}

.delete-button {
  position: absolute;
  right: 10px;
  cursor: pointer;
  margin-top: 15px;
  margin-bottom: 25px;
}

.edit-button {
  position: absolute;
  display: inline-block;
  padding-left: 5px;
  cursor: pointer;
}

.settings-container {
  background-color: white;
  height: 500px;
  width: 470px;
  margin: auto;
  margin-top: 50px;
  margin-bottom: 60px;
  border-radius: 10px;
  padding: 20px;
  box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);
}

.avatar {
  display: inline-block;
  margin: 20px 10px 10px 20px;
}

.avatar-form {
  display: inline-block;
  width: 50%;
  margin: 0px 0px 0px 40px;
}

.change-avatar-style {
  border-radius: 3px;
  width: 82px;
  height: 82px;
  cursor: pointer;
}

.modal-avatar-container {
  text-align: center;
}

.edit-avatar-container {
  display: inline-block;
}

.avatar-buttons-container {
  text-align: center;
  margin-top: 20px;
}

.avatar-buttons button {
  margin: 10px;
  border: 1px solid #39e09b;
  background: transparent;
  cursor: pointer;
}

.color-container {
  text-align: center;
  padding: 10px;
}

.themes-container {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-gap: 22px;
  justify-items: center;
}

.box {
  color: #fff;
  border-radius: 2px;
  height: 81px;
  width: 81px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
}

.default-theme {
  padding: 5px;
  border: 3px solid white;
  border-radius: 10px;
}

.active-theme {
  padding: 5px;
  border: 3px solid gold;
  border-radius: 10px;
}

.empty-linktree {
  color: white;
}

@media only screen and (max-width: 600px) {
  .buttons {
    flex-direction: column;
  }
}

@media only screen and (max-width: 850px) {
  .login-buttons {
    flex-direction: column;
  }

  .newlink-buttons {
    flex-direction: column;
  }

  .user-input {
    padding: 20px 50px 20px 50px;
  }

  #added {
    font-size: 15px;
  }

}

@media only screen and (max-width: 1250px) {
  .signup-buttons {
    flex-direction: column;
  }

  .error-msg {
    top: 10vh;
  }
}

@media only screen and (max-width: 570px) {
  .header-links {
    margin: 20px;
  }

  .themes-container {
    grid-template-columns: 1fr 1fr;
  }

  .settings-container {
    height: 700px;
    width: 50vh;
  }
}

@media only screen and (max-width: 440px) {
  .themes-container {
    grid-template-columns: 1fr 1fr;
    grid-gap: 0px;
  }

  .settings-container {
    height: 600px;
    width: 33vh;
  }

  .avatar-form {
    width: auto;
    margin: 0px 0px 0px 30px;
  }
}

@media only screen and (max-width: 735px) {
  .linksList {
    max-width: 480px;
  }
  
  .edit-url-wrap {
    max-width: 320px;
  }

  .edit-linkTitle-wrap {
    max-width: 320px;
  }
}

@media only screen and (max-width: 540px) {
  .linksList {
    max-width: 320px;
  }

  .edit-url-wrap {
    max-width: 180px;
  }

  .edit-linkTitle-wrap {
    max-width: 180px;
  }
}

@media only screen and (max-width: 320px) {
  .linksList {
    max-width: 300px;
  }
}

@media only screen and (max-height: 730px) {
  .avatar-form {
    width: 70px;
    margin: 0px 0px 0px 20px;
  }
}

@media only screen and (max-height: 660px) {
  .avatar-form {
    margin: 0px 0px 0px 10px;
  }
}

@media only screen and (max-height: 660px) {
  .avatar-form {
    margin: 0px 0px 0px 10px;
  }
}